import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/login/Home.vue'
import Events from '@/views/realTime/Events.vue'
import Statuses from '@/views/realTime/Statuses.vue'
import Irrigation from '@/views/irrigation/Status.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Statuses',
    component: Statuses
  },
  {
    path: '/events',
    name: 'Events',
    component: Events
  },
  {
    path: '/irrigation',
    name: 'Irrigation',
    component: Irrigation
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router;
